<template>
    <div class="bg re_sizing re_relative re_flex_center">
        <div class="modal re_relative re_flex_center">
            <img class="code re_relative" :src="info.inviteQrcode" alt="">
            <img :src="info.photo" alt="" class="codeImg re_absolute">
        </div>
        <div v-if="showMask" class="modal-content re_absolute">
            <img src="https://tp.kaishuihu.com/static/yindao.png" alt="" class="img1">
            <img src="https://tp.kaishuihu.com/static/wenan.png" alt="" class="re_margin_top_24 img2">
            <img @click="showMask = false" src="https://tp.kaishuihu.com/static/anniu.png" alt="" class="re_margin_top_24 img3">
        </div>
        
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue';
const router = useRouter()
import { briefInfo } from './api.js'
const showMask = ref(true)

onMounted(() => {
    getInfo()
    
})
const info = ref({})
const getInfo = async () => {
    briefInfo().then(res => {
        info.value = res.data
        shareGetConfig()
    })
}

</script>

<style lang="less" scoped>
.bg {
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    background: url('../../../assets/invite_qrcode.png') left top no-repeat;
    background-size: 100% 100%;
   .yindao{
        width: 100vw;
        height: 100vh;
   }
}
.mask {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    top: 0;
    left: 0;
    text-align: center;
    position: fixed;
    .bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 340px;
        background: url('../../../assets/invite/intro.png') no-repeat center center;
        background-size: 100% 100%;

        .close {
            top: 20px;
            right: 20px;
            width: 26px;
            height: 26px;
        }

        .disabled {
            width: 344px;
            height: 48px;
            bottom: 40px;
            left: 15px;
        }
    }
}
.modal{
    width: 160px;
    height: 160px;
    border: 2px solid #3185ff;
    border-radius:8px;
    top: 30px;
    .code{
        width: 156px;
        height: 156px;
    }
    .codeImg{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        left: 50%;
        transform: translate(-50%, 0);
    }
}
.modal-content{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    top: 0;
    left: 0;
    img{
        position: relative;
        display: block;
        width: 60%;
        left: 28%;
    }
    .img1{
        height: 200px;
    }
    .img2{
        height: 120px;
    }
    .img3{
        height: 78px;
    }
}
</style>